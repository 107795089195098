<template>
  <div class="fill-heigt" fluid>
    <v-row>
      <v-col cols="12" md="8">
        <div class="text-left subtitle" style="margin-top:3%">
          Painel de Candidatos
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="text-right">
          <v-text-field
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-divider class="my-3"></v-divider>
    <v-row>
      <v-col cols="12" md="12">
        <candidate-table :candidates="getCandidates" @delete="openConfirmeDialog" />
      </v-col>  
    </v-row>  
    <confirm-dialog 
      :dialog="confirm" 
      :id="removeId" 
      question="Pretende remover o Candidato?" 
      @yes="deleteCandidate" 
      @no="confirm = false" 
    />
    <error-dialog :dialog="showError" :message="error" @close="showError = false" />
    <progress-dialog :processing="isLoading" />
    <success-dialog :dialog="showSuccess" :message="success" @close="showSuccess = false" />
  </div> 
</template>

<script>
import { DELETE_CANDIDATE_MUTATION } from './../graphql/Mutation'
import { CANDIDATES_QUERY } from './../graphql/Query'
import { mapActions, mapGetters } from 'vuex'
import { formatError } from '@/utils'
import CandidateTable from '../components/CandidateTable.vue'
import Candidate from '@/models/Candidate'
import ConfirmDialog from './../../../components/ConfirmDialog.vue'
import ErrorDialog from './../../../components/ErrorDialog.vue'
import ProgressDialog from './../../../components/ProgressDialog.vue'
import SuccessDialog from './../../../components/SuccessDialog.vue'
import dialogMixins from '@/mixins/dialog'
export default {
  name: 'Entity',
  components: { CandidateTable, ConfirmDialog, ErrorDialog, ProgressDialog, SuccessDialog },
  mixins: [
    dialogMixins
  ],
  apollo: {
    candidates: {
      query: CANDIDATES_QUERY
    }
  },
  data: () => ({
    candidate: new Candidate(),
    candidates: []
  }),
  watch: {
    candidates: function(val) {
      this.setCandidatessState(val)
    }
  },
  computed: {
    ...mapGetters({
      getCandidates: 'candidate/getCandidates'
    })
  },
  methods: {
    ...mapActions({
      removeCandidateState: 'candidate/removeCandidate',
      setCandidatessState: 'candidate/setCandidates'
    }),
    async deleteCandidate (id) {
      this.confirm = false
      this.isLoading = true
      try {
        await this.$apollo.mutate({
          mutation: DELETE_CANDIDATE_MUTATION,
          variables: {
            id
          }
        })
        this.removeCandidateState(id)
        this.success = 'Candidato removido com sucesso!'
        this.showSuccess = true
      } catch (error) {
        this.error = formatError(error.message)
        this.showError = true
      } finally {
        this.isLoading = false
      }
    },
    openConfirmeDialog (id) {
      this.removeId = id
      this.confirm = true
    },
  }
}
</script>